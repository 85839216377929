import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';

const SocialMediaIcons = () => {
  const iconSize = '35px'; // Fixed size for icons

  const socialMedia = [
    { icon: faYoutube, url: 'https://www.youtube.com/channel/UCjRRL1aKFWhwDwVTeP0JLfg', color: '#FF0000' },
    { icon: faLinkedin, url: 'https://www.linkedin.com/in/intern-steps-22ba95325/', color: '#0A66C2' },
    { icon: faInstagram, url: 'https://www.instagram.com/internsteps4u/', gradient: 'linear-gradient(45deg, #FCAF45 0%, #F56040 25%, #C13584 50%, #833AB4 75%, #405DE6 100%)' },
  ];

  return (
    <div
      className="position-fixed top-50 translate-middle-y d-flex flex-column align-items-center"
      style={{ right: '5px' }} // Custom style for right positioning
    >
      {/* Social Media Icons */}
      {socialMedia.map((social, index) => (
        <a
          key={index}
          href={social.url}
          target="_blank"
          rel="noopener noreferrer"
          className="d-flex align-items-center justify-content-center btn rounded-circle mb-2"
          style={{
            background: social.gradient || social.color, // Apply gradient or solid color
            border: 'none',
            color: 'white',
            width: iconSize,
            height: iconSize,
            fontSize: '25px',
            transition: 'transform 0.2s',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
          }}
          onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
          onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
        >
          <FontAwesomeIcon icon={social.icon} />
        </a>
      ))}
    </div>
  );
};

export default SocialMediaIcons;
