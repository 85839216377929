import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated for React 18
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import './index.css';
import InterviewPreparation from './pages/Interviewpreparation'; // Import InterviewPreparation
import TutorialBox from './pages/TutorialBox'; // Import InterviewPreparation


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route exact path="/" element={<App />} />
        <Route exact path="/interview-preparation" element={<InterviewPreparation />} />
        <Route exact path="/tutorial-box" element={<TutorialBox />} />

      </Routes>
    </Router>
  </React.StrictMode>
);
