/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHtml5, faCss3Alt, faJs, faReact, faAngular, faVuejs, faSass, faBootstrap, faPython, faNode, faJava, faPhp, faGitAlt, faAws } from '@fortawesome/free-brands-svg-icons';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';

const TutorialBox = () => {
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [searchQuery, setSearchQuery] = useState('');

    const technologies = [
        { name: 'HTML', icon: faHtml5, color: '#E34F26', category: 'Frontend' },
        { name: 'CSS', icon: faCss3Alt, color: '#1572B6', category: 'Frontend' },
        { name: 'JavaScript', icon: faJs, color: '#F7DF1E', category: 'Frontend' },
        { name: 'React', icon: faReact, color: '#61DAFB', category: 'Frontend' },
        { name: 'Angular', icon: faAngular, color: '#DD0031', category: 'Frontend' },
        { name: 'Vue.js', icon: faVuejs, color: '#42b883', category: 'Frontend' },
        { name: 'Sass', icon: faSass, color: '#CC6699', category: 'Frontend' },
        { name: 'Bootstrap', icon: faBootstrap, color: '#7952B3', category: 'Frontend' },
        { name: 'Python', icon: faPython, color: '#306998', category: 'Backend' },
        { name: 'Node.js', icon: faNode, color: '#68A063', category: 'Backend' },
        { name: 'Java', icon: faJava, color: '#007396', category: 'Backend' },
        { name: 'PHP', icon: faPhp, color: '#777BB4', category: 'Backend' },
        { name: 'Git', icon: faGitAlt, color: '#F05032', category: 'Fullstack' },
        { name: 'AWS', icon: faAws, color: '#FF9900', category: 'Fullstack' },
        { name: 'MySQL', icon: faDatabase, color: '#4479A1', category: 'Backend' },
        { name: 'Tailwind CSS', icon: faPython, color: '#06B6D4', category: 'Frontend' },
    ];

    // Filter technologies based on selected category and search query
    const filteredTechnologies = technologies
        .filter(tech => (selectedCategory === 'All' || tech.category === selectedCategory) &&
            tech.name.toLowerCase().includes(searchQuery.toLowerCase()));

    return (
        <div className="container min-vh-100 py-4">
            {/* Navbar Section */}
            <nav className="navbar navbar-expand-lg navbar-light bg-light mb-4">
                <div className="container-xxl">
                    <a className="navbar-brand" href="#">
                        Tech Tutorials
                    </a>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            {['All', 'Frontend', 'Backend', 'Fullstack'].map((category) => (
                                <li className="nav-item" key={category}>
                                    <button
                                        className={`nav-link btn ${selectedCategory === category ? 'active' : ''}`}
                                        onClick={() => setSelectedCategory(category)}
                                    >
                                        {category}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </nav>


            {/* Search Input Section */}
            <div className="mb-4 text-center">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search for a technology..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            {/* Cards Section */}
            <div className="row row-cols-1 row-cols-md-4 g-4">
                {filteredTechnologies.map((tech) => (
                    <div className="col" key={tech.name}>
                        <div className="card h-100 shadow-sm">
                            <div className="card-body d-flex flex-column align-items-center text-center">
                                <FontAwesomeIcon icon={tech.icon} size="3x" style={{ color: tech.color }} className="mb-3" />
                                <h5 className="card-title">{tech.name}</h5>
                                <button className="btn btn-outline-primary mt-auto">
                                    Learn {tech.name}
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}


export default TutorialBox;
