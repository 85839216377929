import React from "react";
import IconCloud from "./IconCloud";
import heroImage from "../assets/img/hero-1.png";

const slugs = [
  "html", "css", "bootstrap", "javascript", "typescript", "react",
  "angular", "vue", "css3", "sass", "tailwindcss", "nodedotjs", "java",
  "python", "php", "express", "spring", "django", "laravel", "aspnet",
  "restapi", "graphql", "webpack", "babel", "vite", "postgresql", "mysql",
  "mariadb", "mongodb", "redis", "firebase", "AWS",
];

const IconCloudDemo = ({ darkMode }) => (
  <main>
    <section id="home" className={`container text-${darkMode ? 'light' : 'dark'} py-5`} style={{ minHeight: 'calc(100vh - 70px)' }}>
      <style>
        {`
          @keyframes typing {
            from { width: 0; }
            to { width: 100%; }
          }

          @keyframes cursorBlink {
            0% { border-right: 2px solid transparent; }
            100% { border-right: 2px solid; }
          }

          .typing-animation {
            overflow: hidden;
            white-space: nowrap;
            border-right: 2px solid;
            animation: typing 4s steps(40, end) 1, cursorBlink 0.75s step-end infinite;
            font-family: 'Consolas', monospace;
            font-size: 2rem;
            font-weight: 600;
            display: inline-block;
          }
          
          .image-shadow {
            {/* filter: drop-shadow(10px 0px 6px rgba(0, 0, 0, 0.2)); /* Drop shadow applied here */ */}
          }
        `}
      </style>

      <header className="text-center mb-4">
        <h1 className={`mb-2 ${darkMode ? 'text-light' : 'text-dark'} typing-animation`}>
          "Empower Your Career with <strong><mark>InternSteps"</mark></strong>
        </h1>
        <p className={`mb-3 ${darkMode ? 'text-light' : 'text-dark'}`} style={{ fontSize: '1.1rem', lineHeight: '1.5' }}>
          Find exciting opportunities and accelerate your career path.
        </p>
      </header>

      <div className="row align-items-center">
        <div className="col-md-6 d-flex justify-content-center mb-3 mb-md-0">
          <img className="img-fluid rounded image-shadow" src={heroImage} alt="Empowering Career Opportunities" style={{ width: '90%', maxWidth: '500px' }} />
        </div>

        <div className="col-md-6 d-flex justify-content-center align-items-center">
          <IconCloud iconSlugs={slugs} style={{ width: '90%', maxWidth: '500px' }} />
        </div>
      </div>
    </section>
  </main>
);

export default IconCloudDemo;
