import React, { useEffect, useState } from 'react';
import { GiRocket } from 'react-icons/gi';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const ScrollButtons = () => {
  const [isAtTop, setIsAtTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => setIsAtTop(window.scrollY === 0);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scroll = (direction) => window.scrollTo({
    top: direction === 'top' ? 0 : document.documentElement.scrollHeight,
    behavior: 'smooth'
  });

  return (
    <div className="position-fixed bottom-0 end-0 p-3">
      <button
        onClick={() => scroll(isAtTop ? 'bottom' : 'top')}
        aria-label={isAtTop ? 'Scroll to bottom' : 'Scroll to top'}
        className="btn d-flex align-items-center justify-content-center"
        style={{
          background: 'none',
          border: 'none',
          fontSize: '40px',
          color: 'var(--icon-color)' // Use CSS variable for color
        }}
      >
        <GiRocket
          style={{
            transform: isAtTop ? 'rotate(0deg)' : 'rotate(180deg)',
            transition: 'transform 0.3s'
          }}
        />
      </button>
    </div>
  );
};

export default ScrollButtons;
