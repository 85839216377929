import React from 'react';
import { Link } from 'react-router-dom';
import navBarlogo from '../assets/img/internstepslogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon, faBars } from '@fortawesome/free-solid-svg-icons';
import '../style/Navbar.css'; // Ensure to import your CSS file here

const Navbar = ({ darkMode, toggleTheme }) => {
  const textClass = darkMode ? 'text-white' : 'text-primary';
  const bgClass = darkMode ? 'bg-dark' : 'bg-light';

  return (
    <nav className={`navbar navbar-expand-lg ${bgClass} shadow-sm sticky-top`}>
      <div className="container">
        {/* Logo Section */}
        <Link to="/" className="navbar-brand d-flex align-items-center">
          <img src={navBarlogo} alt="Logo" className="me-2" style={{ width: '40px', height: 'auto' }} />
          <h5 className={`m-0 ${textClass}`}>Intern Steps</h5>
        </Link>

        {/* Toggler Button for Mobile */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <FontAwesomeIcon icon={faBars} className={textClass} />
        </button>

        {/* Navbar Collapse */}
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            {['Home', 'Service', 'Categories', 'Courses', 'Team', 'Testimonial', 'Contact'].map((item) => (
              <li key={item} className="nav-item">
                <a href={`#${item.toLowerCase()}`} className={`nav-link ${textClass}`}>
                  {item}
                </a>
              </li>
            ))}
            {/* Theme Toggle Button */}
            <li className="nav-item d-flex align-items-center justify-content-center">
              <button
                onClick={toggleTheme}
                className={`btn btn-sm ms-3 d-flex align-items-center justify-content-center`}
                style={{
                  backgroundColor: darkMode ? '#333' : '#f8f9fa', // Darker shade for dark mode, lighter for light mode
                  color: darkMode ? '#ffdd57' : '#333', // Yellow for sun icon and dark text for moon icon
                  border: '2px solid',
                  borderColor: darkMode ? '#ffdd57' : '#333', // Yellow border for dark mode and dark border for light mode
                  borderRadius: '20px', // Rounded button
                  padding: '6px 12px', // Adjusted padding for better spacing
                  cursor: 'pointer',
                  transition: 'background-color 0.3s, color 0.3s, border-color 0.3s', // Smooth transition for theme change
                }}
              >
                <FontAwesomeIcon icon={darkMode ? faSun : faMoon} className="me-2" />
                {darkMode ? 'Light' : 'Dark'}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
